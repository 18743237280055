import React from 'react'
import PropTypes from 'prop-types'
import { useMediaQuery } from '@material-ui/core'

import {
  Footer
} from '@findep/mf-landings-core'


function FooterFindep({ broker, companyName, data }) {

  let matches = useMediaQuery('(min-width:768px)');
  let footer = []
  if ((broker !== undefined && broker !== null)) {
    footer = data.allComponentsJson.edges[0].node.footer.find(item => (item.company === companyName && item.broker === broker))
    if (footer === undefined) {
      footer = data.allComponentsJson.edges[0].node.footer.find(item => item.company === companyName)
    }
  } else {
    footer = data.allComponentsJson.edges[0].node.footer.find(item => item.company === companyName)
  }

  let ca = []
  footer.condusefarray.map((row) => {
    return ca.push({ imagen: (<img src={row.src.publicURL} alt="" width="91" height="48" />), texto: row.texto, link: row.link })
  })
  let it = []
  footer.imagestext.map((row) => {
    return it.push({ imagen: (<img src={row.src.publicURL} alt="" width="20" height="20" />), texto: row.texto, extra: row.extra, link: row.link })
  })
  let ba = []
  footer.buroarray.map((row) => {
    return ba.push({ imagen: (<img src={row.src.publicURL} alt="" width="46" height="46" />), texto: row.texto, extra: row.extra, link: row.link })
  })
  let leyendas = footer.leyendas
  let linkone = footer.linkone
  let linktree = footer.linktree
  let linktwo = footer.linktwo
  let terminosyavisos = footer.terminosyavisos
  let terminosyavisosafileft = footer.terminosyavisosafileft
  let terminosyavisosafiright = footer.terminosyavisosafiright
  
  let otr = []
  footer.otros.map((row) => {
    return otr.push({ imagen: (<img src={row.src.publicURL} alt="" width="122" height="48" />), link: row.link })
  })
  return (
    <>
      <div style={{ marginBottom: matches ? '60px' : '20px' }} />
      {companyName === "AFI"
        ?
        <Footer afi terminosyavisosafiright={terminosyavisosafiright} terminosyavisosafileft={terminosyavisosafileft} fontsectionone={{ family: 'Roboto-Condensed', size: '14px', redesSize: '17px' }} linkone={linkone} linktwo={linktwo} linktree={linktree} leyendas={leyendas} colorfooter="#eeedf0" condusefarray={ca} buroarray={ba} otros={otr} imagestext={it} />
        :
        <Footer terminosyavisos={terminosyavisos} fontsectionone={{ family: 'Roboto-Condensed', size: '14px', redesSize: '17px' }} linkone={linkone} linktwo={linktwo} linktree={linktree} leyendas={leyendas} colorfooter="#eeedf0" condusefarray={ca} buroarray={ba} otros={otr} imagestext={it} />
      }
    </>
  )
}

FooterFindep.propTypes = {
  companyName: PropTypes.oneOf(['AEF', 'AFI', 'FISA']),
  data: PropTypes.object.isRequired
}

export default FooterFindep

